import React, { useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  PreviewAltCard,
} from "../components/Component";
import {
  FileTextOutlined, // This is the new icon for invoices
  DollarOutlined,
  ExclamationCircleOutlined,
  ShoppingCartOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  FileProtectOutlined,
  PauseCircleOutlined
} from "@ant-design/icons";
import { formatCurrency2, formatCurrency } from '../utils/Utils';
import { Row, Col, Card, Tooltip } from "antd";
import QuotationsVsAwardsChart from "./dashboard/QuotationsVsAwardsChart";
import InvoiceToPaymentSummaryWidget from "./dashboard/InvoiceToPaymentSummaryWidget";
import OverdueSummaryWidget from "./dashboard/OverdueSummaryWidget";
import OutstandingPaymentsWidget from "./dashboard/OutstandingPaymentsWidget";
const InvestHomePage = () => {
  const [sm, updateSm] = useState(false);

  return (
    <React.Fragment>
      <Head title="Procurex Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Dashboard</BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to Procurex.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button color="primary" outline className="btn-dim btn-white">
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" outline className="btn-dim btn-white">
                        <Icon name="reports"></Icon>
                        <span>Reports</span>
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                      <UncontrolledDropdown>
                        <DropdownToggle color="transparent" className="dropdown-toggle btn btn-icon btn-primary">
                          <Icon name="plus"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                href="#adduser"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="user-add-fill"></Icon>
                                <span>Add User</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                href="#addorder"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="coin-alt-fill"></Icon>
                                <span>Add Order</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                href="#addpage"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="note-add-fill-c"></Icon>
                                <span>Add Page</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="d-flex justify-content-between">

          <Row className="w-30 d-flex dashboard-side-widget">
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Quotations to Orders</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>147</span>
                    <span style={{ fontSize: "14px", marginLeft: "5px" }}>/217</span>
                    <span style={{ fontSize: "14px", marginLeft: "5px", color: "green" }}>(66.2%)</span>
                  </span>
                  <FileTextOutlined style={{ color: "#5470C6", fontSize: 32 }} />
                </div>
              </Card>
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Order Value</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    {formatCurrency2('18642289', 'INR')}
                    <Tooltip title={formatCurrency('18642289', 'INR')}>
                      <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                    </Tooltip>
                    {/* <span style={{ fontSize: "14px", marginLeft: "5px", color: "red" }}>(14.81%)</span> */}

                  </span>
                  <ShoppingCartOutlined style={{ color: "#5470C6", fontSize: 32 }} />
                </div>
              </Card>
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Invoiced Value</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    {formatCurrency2('12435640', 'INR')}
                    <Tooltip title={formatCurrency('12435640', 'INR')}>
                      <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                    </Tooltip>
                  </span>
                  <FileProtectOutlined style={{ color: "#5470C6", fontSize: 32 }} />
                  {/* <DollarOutlined style={{ color: "#5470C6", fontSize: 32 }} /> */}
                </div>
              </Card>
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Payments Received</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    {formatCurrency2('5892147', 'INR')}
                    <Tooltip title={formatCurrency('5892147', 'INR')}>
                      <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                    </Tooltip>
                    <span style={{ fontSize: "14px", marginLeft: "5px", color: "green" }}>(47.38%)</span>
                  </span>
                                <CheckCircleOutlined style={{ color: "#5470C6", fontSize: 32 }} />
                  {/* <ExclamationCircleOutlined style={{ color: "#5470C6", fontSize: 32 }} /> */}
                </div>
              </Card>
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Outstanding Value</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    {formatCurrency2('4701206', 'INR')}
                    <Tooltip title={formatCurrency('4701206', 'INR')}>
                      <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                    </Tooltip>
                    <span style={{ fontSize: "14px", marginLeft: "5px", color: "orange" }}>(37.81%)</span>

                  </span>
                  <PauseCircleOutlined style={{ color: "#5470C6", fontSize: 32 }} />
                  {/* <ShoppingCartOutlined style={{ color: "#5470C6", fontSize: 32 }} /> */}
                </div>
              </Card>
              <Card className="w-100">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Overdue Value</h6>
                  </div>
                </div>
                <div className="card-amount d-flex justify-content-between">
                  <span className="amount">
                    {formatCurrency2('1842289', 'INR')}
                    <Tooltip title={formatCurrency('1842289', 'INR')}>
                      <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                    </Tooltip>
                    <span style={{ fontSize: "14px", marginLeft: "5px", color: "red" }}>(14.81%)</span>

                  </span>
                  <ExclamationCircleOutlined style={{ color: "#5470C6", fontSize: 32 }} />
                </div>
              </Card>
            
          </Row>
          <Row className="w-70">
              <Card className="w-100">
                <QuotationsVsAwardsChart />
              </Card>
          </Row>

          </div>
          <Row gutter={[16, 16]} style={{marginTop: 20}}>
            <Col span={8}>
              <Card className="card-full">
                <InvoiceToPaymentSummaryWidget></InvoiceToPaymentSummaryWidget>
              </Card>
            </Col>
            <Col span={8}>
              <Card className="card-full">
                <OverdueSummaryWidget></OverdueSummaryWidget>
              </Card>
            </Col>
            <Col span={8}>
              <Card className="card-full">
                <OutstandingPaymentsWidget></OutstandingPaymentsWidget>
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default InvestHomePage;
