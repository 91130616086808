import React, { useState, useEffect, FC } from "react";
import Content from "../../../layout/content/Content";
import { Col, Row } from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Button,
  Icon,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
} from "../../../components/Component";
import "react-tooltip/dist/react-tooltip.css";
import Swal from "sweetalert2";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Modal,
  ModalBody,
} from "reactstrap";
import EditModal from "./EditModal";
import AddModal from "./AddModal";
import departments from "../../../api/master/departments";
import FormatDate from "../DateFormater";
import Export from "../../../components/export/Export";

const Departments: FC = () => {
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [modal, setModal]: any = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [formData, setFormData]: any = useState({
    referenceID: "",
    deptName: "",
    teamCount: Number,
    description: "",
  });
  const [editFormData, setEditFormData]: any = useState({
    referenceID: "",
    deptName: "",
    teamCount: Number,
    description: "",
  });
  const [viewModal, setViewModal] = useState(false);
  const [detail, setDetail]: any = useState({});

  const loadDetail = (id: any) => {
    let index = rowData.findIndex((item: any) => item.deptID === id);
    setDetail(rowData[index]);
  };

  const closeModal = () => {
    setModal({ add: false });
    resetForm();
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
  };

  const closeEditModal = () => {
    setModal({ edit: false });
    resetForm();
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
  };

  const resetForm = () => {
    setFormData({
      referenceID: "",
      deptName: "",
      teamCount: Number,
      description: "",
    });
  };

  const onFormSubmit = async (submitData: any) => {
    if (!submitData.teamCount) {
      submitData.teamCount = null;
    }
    const response = await departments.createDept(submitData);
    console.log("status:::message", response);
    const { status, message } = response;
    if (status) {
      Swal.fire("Created!", "Department has been created", "success");
      resetForm();
      setModal({ edit: false }, { add: false });
      fetchDept();
    } else {
      // Swal.fire("Failed!", message, "error");
      let errorMessage = "An error occurred";
      const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };

  const onEditSubmit = async (submitData: any) => {
    const { referenceID, deptName, teamCount, description } = submitData;
    if (!submitData.teamCount) {
      submitData.teamCount = null;
    }
    const { status, message } = await departments.updateDeptById(
      editId,
      submitData
    );
    if (status) {
      Swal.fire("Updated!", "Department has been updated", "success");
      resetForm();
      setModal({ edit: false }, { add: false });
      fetchDept();
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  const [rowData, setRowData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const fetchDept = async () => {
    try {
      const { data, status, message } = await departments.fetchDeptData();
      if (status) {
        setRowData(data);
        setOriginalData(data); // Store the original data
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };

  const handleDelete = (deptID: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { status, message } = await departments.deleteDeptById(deptID);
          if (status) {
            Swal.fire("Deleted!", "Department has been deleted", "success");
            fetchDept();
          } else {
            Swal.fire("Error", message, "error");
          }
        } catch (err: any) {
          Swal.fire(
            "Error",
            err.response?.data?.message || err.message,
            "error"
          );
        }
      }
    });
  };

  const onEditClick = (deptID: any) => {
    rowData.forEach((item: any) => {
      if (item.deptID === deptID) {
        setEditFormData({
          referenceID: item.referenceID,
          deptName: item.deptName,
          teamCount: item.teamCount,
          description: item.description,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(deptID);
      }
    });
  };

  const onFilterChange = (e: any) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    fetchDept();
  }, []);

  useEffect(() => {
    if (onSearchText !== "") {
      const searchText = onSearchText.toLowerCase();
  
      const filteredObject = originalData.filter((item: any) => {
        return Object.values(item).some((value: any) => {
          // Convert value to string and then perform case-insensitive search
          return value && value.toString().toLowerCase().includes(searchText);
        });
      });
      setRowData(filteredObject); // Update state with filtered data
    } else {
      setRowData(originalData); // Reset to original data
    }
  }, [onSearchText, originalData]);


  const sortFunc = (params: any) => {
    let defaultData = rowData;
    if (params === "asc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        a.createdDate.localeCompare(b.createdDate)
      );
      setRowData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        b.createdDate.localeCompare(a.createdDate)
      );
      setRowData([...sortedData]);
    }
  };
  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = rowData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Construction Sites"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Department Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {rowData.length} Department.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Export data={rowData} />
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        color="primary"
                        onClick={() => {
                          setModal({ add: true });
                          setAlertInfo({
                            type: "", // 'success' or 'error'
                            message: "",
                            errors: [],
                          });
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add </span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable
            className="card-stretch"
            bodyClassName={undefined}
            title={undefined}
          >
            <div className="card-inner position-relative card-tools-toggle">
              <div className={`card-search search-wrap active`}>
                <div className="card-body">
                  <div
                    className="search-content"
                    style={{ whiteSpace: "nowrap", display: "flex" }}
                  >
                    <Button
                      className="search-back btn-icon toggle-search active"
                    >
                      <Icon name="search"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="btn btn-trigger btn-icon dropdown-toggle"
                      >
                        <Icon name="filter"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end className="dropdown-menu-xs">
                        <ul className="link-check">
                          <li>
                            <span>Show</span>
                          </li>
                          <li className={itemPerPage === 10 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(10);
                              }}
                            >
                              10
                            </DropdownItem>
                          </li>
                          <li className={itemPerPage === 15 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(15);
                              }}
                            >
                              15
                            </DropdownItem>
                          </li>
                        </ul>
                        <ul className="link-check">
                          <li>
                            <span>Order</span>
                          </li>
                          <li className={sort === "dsc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("dsc");
                                sortFunc("dsc");
                              }}
                            >
                              DESC
                            </DropdownItem>
                          </li>
                          <li className={sort === "asc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("asc");
                                sortFunc("asc");
                              }}
                            >
                              ASC
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            </div>

            <DataTableBody compact className={undefined} bodyclass={undefined}>
              <DataTableHead>
                <DataTableRow className={undefined} size={undefined}>
                  <span className="sub-text">#</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Department ID</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Department Name</span>
                </DataTableRow>
                <DataTableRow size="sm" className={undefined}>
                  <span className="sub-text">Description</span>
                </DataTableRow>
                <DataTableRow size="sm" className={undefined}>
                  <span className="sub-text">Team Count</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  {/* <span className="sub-text">Details</span> */}
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item: any, index) => {
                    return (
                      <DataTableItem
                        key={item.id}
                        className={undefined}
                        style={{ color: "black" }}
                      >
                        <DataTableRow size="md" className={undefined}>
                          <span>{index + 1}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.referenceID}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.deptName}</span>
                        </DataTableRow>
                        <DataTableRow size="sm" className={undefined}>
                          <span id={`desc-tooltip-${index}`}>
                            {item.description.length > 20
                              ? `${item.description!.substring(0, 25)}... `
                              : item.description}
                          </span>
                          {item.description.length > 20 && (
                            <TooltipComponent
                              iconClass="info-circle text-info"
                              icon="info"
                              id={`desc-tooltip-${index}`}
                              direction="top"
                              text={item.description}
                              containerClassName={""}
                            />
                          )}

                          {/* <span>{item.description}</span> */}
                        </DataTableRow>
                        <DataTableRow size="sm" className={undefined}>
                          <span>{item.teamCount}</span>
                        </DataTableRow>
                      
                        <DataTableRow size="md" className={undefined}>
                          <span
                            className={`tb-status text-${
                              item.status === "Active" ? "success" : "danger"
                            }`}
                          >
                            {item.status}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <ul className="nk-tb-actions">
                            <li
                              className="nk-tb-action-hidden"
                              onClick={() => onEditClick(item.deptID)}
                            >
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                                iconClass={undefined}
                              />
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li
                                      onClick={() => onEditClick(item.deptID)}
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          loadDetail(item.deptID);
                                          setViewModal(true);
                                        }}
                                      >
                                        <Icon name="focus"></Icon>
                                        <span>View Details</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          handleDelete(item.deptID);
                                        }}
                                      >
                                        <Icon name="trash"></Icon>
                                        <span>Delete</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={rowData.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>

        <AddModal
          modal={modal.add}
          formData={formData}
          setFormData={setFormData}
          closeModal={closeModal}
          onSubmit={onFormSubmit}
          filterStatus
          alertInfo={alertInfo}
        />
        <EditModal
          modal={modal.edit}
          formData={editFormData}
          setFormData={setEditFormData}
          closeModal={closeEditModal}
          onSubmit={onEditSubmit}
          filterStatus
          alertInfo={alertInfo}
        />
      </Content>

      <Modal
        isOpen={viewModal}
        toggle={() => setViewModal(false)}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setViewModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="nk-modal-head">
            <h4 className="nk-modal-title title">
              Department Details -{" "}
              <small className="text-primary"> {detail.deptName}</small>
            </h4>
          </div>
          <div className="nk-tnx-details mt-sm-3">
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text"> Reference ID</span>
                <span className="caption-text">{detail.referenceID}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Department Name </span>
                <span className="caption-text text-break">
                  {detail.deptName}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Team Count </span>
                <span className="caption-text">{detail.teamCount}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Status</span>
                <Badge
                  color={detail.status === "Active" ? "success" : "danger"}
                  size="md"
                >
                  {detail.status}
                </Badge>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Description</span>
                <span className="caption-text"> {detail.description}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Date</span>
                <span className="caption-text">
                  {" "}
                  {FormatDate(detail.createdDate)}
                </span>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Departments;
